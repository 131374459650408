define("discourse/plugins/discourse-subscription-client/discourse/mixins/notice-message", ["exports", "@ember/object/mixin", "@ember/runloop", "discourse/lib/text", "@popperjs/core"], function (_exports, _mixin, _runloop, _text, _core) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create({
    showMessage: false,
    didReceiveAttrs() {
      const message = this.notice.message;
      (0, _text.cookAsync)(message).then(cooked => {
        this.set("cookedMessage", cooked);
      });
    },
    createMessageModal() {
      let container = this.element.querySelector(".notice-message");
      let modal = this.element.querySelector(".notice-message-content");
      this._popper = (0, _core.createPopper)(container, modal, {
        strategy: "absolute",
        placement: "auto",
        modifiers: [{
          name: "preventOverflow"
        }, {
          name: "offset",
          options: {
            offset: [0, 5]
          }
        }]
      });
    },
    didInsertElement() {
      $(document).on("click", (0, _runloop.bind)(this, this.documentClick));
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    },
    documentClick(event) {
      if (this._state === "destroying") {
        return;
      }
      if (!event.target.closest(`[data-notice-id="${this.notice.id}"] .notice-message`)) {
        this.set("showMessage", false);
      }
    },
    actions: {
      toggleMessage() {
        this.toggleProperty("showMessage");
        if (this.showMessage) {
          (0, _runloop.scheduleOnce)("afterRender", this, this.createMessageModal);
        }
      }
    }
  });
});
define("discourse/plugins/discourse-subscription-client/discourse/templates/admin-plugins-subscription-client-no-access", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{subscription-client-message
    key="no_access"
    type="info"
    component="subscriptions"}}
  */
  {
    "id": "ifMq4b1R",
    "block": "[[[1,[28,[35,0],null,[[\"key\",\"type\",\"component\"],[\"no_access\",\"info\",\"subscriptions\"]]]]],[],false,[\"subscription-client-message\"]]",
    "moduleName": "discourse/plugins/discourse-subscription-client/discourse/templates/admin-plugins-subscription-client-no-access.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-subscription-client/discourse/models/subscription-client-subscription", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object"], function (_exports, _ajax, _ajaxError, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SubscriptionClientSubscription = _object.default.extend();
  const basePath = "/admin/plugins/subscription-client";
  SubscriptionClientSubscription.reopenClass({
    status() {
      return (0, _ajax.ajax)(`${basePath}/subscriptions`, {
        type: "GET"
      }).catch(_ajaxError.popupAjaxError);
    },
    update() {
      return (0, _ajax.ajax)(`${basePath}/subscriptions`, {
        type: "POST"
      }).catch(_ajaxError.popupAjaxError);
    },
    list() {
      return (0, _ajax.ajax)(`${basePath}/subscriptions`).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = SubscriptionClientSubscription;
});
define("discourse/plugins/discourse-subscription-client/discourse/controllers/admin-plugins-subscription-client", ["exports", "@ember/controller", "@ember/utils"], function (_exports, _controller, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    adminPluginsSubscriptionClientNotices: (0, _controller.inject)(),
    messageType: "info",
    messageKey: null,
    unsubscribe() {
      this.messageBus.unsubscribe("/subscription_client");
    },
    subscribe() {
      this.unsubscribe();
      this.messageBus.subscribe("/subscription_client", data => {
        if ((0, _utils.isPresent)(data.authorized_supplier_count)) {
          this.set("authorizedSupplierCount", data.authorized_supplier_count);
        }
      });
    }
  });
});
define("discourse/plugins/discourse-subscription-client/discourse/components/subscription-client-notice-row", ["exports", "@ember/component", "discourse/plugins/discourse-subscription-client/discourse/mixins/notice-message", "@ember/object/computed"], function (_exports, _component, _noticeMessage, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_noticeMessage.default, {
    tagName: "tr",
    attributeBindings: ["notice.id:data-notice-id"],
    classNameBindings: [":subscription-client-notice-row", "notice.typeClass", "notice.expired:expired", "notice.dismissed:dismissed"],
    canHide: (0, _computed.readOnly)("can_hide"),
    actions: {
      dismiss() {
        this.notice.dismiss();
      },
      hide() {
        this.notice.hide();
      },
      show() {
        this.notice.show();
      }
    }
  });
});
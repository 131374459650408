define("discourse/plugins/discourse-subscription-client/discourse/components/subscription-client-supplier-row", ["exports", "@ember/component", "discourse/plugins/discourse-subscription-client/discourse/models/subscription-client-supplier", "discourse-common/utils/decorators", "@ember/object/computed", "I18n"], function (_exports, _component, _subscriptionClientSupplier, _decorators, _computed, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("supplier.authorized"), (_obj = {
    tagName: "tr",
    classNames: ["subscription-client-supplier-row"],
    authorized: (0, _computed.notEmpty)("supplier.authorized_at"),
    status(authorized) {
      let key = authorized ? "authorized" : "not_authorized";
      return _I18n.default.t(`admin.subscription_client.supplier.${key}`);
    },
    actions: {
      authorize() {
        _subscriptionClientSupplier.default.authorize(this.supplier.id);
      },
      deauthorize() {
        this.set("unauthorizing", true);
        _subscriptionClientSupplier.default.deauthorize(this.supplier.id).then(result => {
          if (result.success) {
            this.set("supplier", result.supplier);
          }
        }).finally(() => {
          this.set("unauthorizing", false);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "status", [_dec], Object.getOwnPropertyDescriptor(_obj, "status"), _obj)), _obj)));
});
define("discourse/plugins/discourse-subscription-client/discourse/routes/admin-plugins-subscription-client-subscriptions", ["exports", "discourse/plugins/discourse-subscription-client/discourse/models/subscription-client-subscription", "discourse/routes/discourse", "@ember/array"], function (_exports, _subscriptionClientSubscription, _discourse, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _subscriptionClientSubscription.default.list();
    },
    setupController(controller, model) {
      controller.setProperties({
        subscriptions: (0, _array.A)(model.subscriptions)
      });
    }
  });
});
define("discourse/plugins/discourse-subscription-client/discourse/routes/admin-plugins-subscription-client", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-subscription-client/discourse/models/subscription-client"], function (_exports, _discourse, _subscriptionClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    beforeModel() {
      if (!this.currentUser.can_manage_subscriptions) {
        this.set("noAccess", true);
        this.transitionTo("adminPlugins.subscriptionClient.noAccess");
      }
    },
    model() {
      if (this.noAccess) {
        return {};
      } else {
        return _subscriptionClient.default.show();
      }
    },
    afterModel(model, transition) {
      if (transition.to.name === "adminPlugins.subscriptionClient.index") {
        this.transitionTo("adminPlugins.subscriptionClient.subscriptions");
      }
    },
    setupController(controller, model) {
      if (this.noAccess) {
        controller.setProperties({
          noAccess: true
        });
      } else {
        controller.setProperties({
          authorizedSupplierCount: model.authorized_supplier_count,
          resourceCount: model.resource_count
        });
        controller.subscribe();
      }
    }
  });
});
define("discourse/plugins/discourse-subscription-client/discourse/controllers/admin-plugins-subscription-client-notices", ["exports", "@ember/controller", "discourse/plugins/discourse-subscription-client/discourse/models/subscription-client-notice", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/array"], function (_exports, _controller, _subscriptionClientNotice, _decorators, _computed, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.observes)("currentUser.subscription_notice_count"), (_obj = {
    queryParams: ["all", "hidden"],
    messageKey: "info",
    messageClass: "info",
    hasNotices: (0, _computed.notEmpty)("notices"),
    hasHiddenNotices: (0, _computed.gt)("hiddenNoticeCount", 0),
    page: 0,
    loadingMore: false,
    canLoadMore: true,
    hidden: false,
    all: false,
    visibleNoticeCountChanged() {
      this.setProperties({
        notices: (0, _array.A)(),
        page: 0,
        canLoadMore: true
      });
      this.loadMoreNotices();
    },
    loadMoreNotices() {
      if (!this.canLoadMore) {
        return;
      }
      const opts = {
        page: this.get("page"),
        visible: !this.get("hidden"),
        include_all: this.get("all")
      };
      this.set("loadingMore", true);
      _subscriptionClientNotice.default.list(opts).then(result => {
        this.set("hiddenNoticeCount", result.hidden_notice_count);
        if (result.notices.length === 0) {
          this.set("canLoadMore", false);
          return;
        }
        this.get("notices").pushObjects((0, _array.A)(result.notices.map(notice => _subscriptionClientNotice.default.create(notice))));
      }).finally(() => this.set("loadingMore", false));
    },
    actions: {
      loadMore() {
        if (this.canLoadMore) {
          this.set("page", this.page + 1);
          this.loadMoreNotices();
        }
      },
      toggleHidden(e) {
        if (e) {
          e.stopPropagation();
        }
        this.toggleProperty("hidden");
      },
      toggleAll(e) {
        if (e) {
          e.stopPropagation();
        }
        this.toggleProperty("all");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "visibleNoticeCountChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "visibleNoticeCountChanged"), _obj)), _obj)));
});
define("discourse/plugins/discourse-subscription-client/discourse/subscription-client-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "admin.adminPlugins",
    path: "/plugins",
    map() {
      this.route("subscriptionClient", {
        path: "/subscription-client"
      }, function () {
        this.route("subscriptions", {
          path: "/subscriptions"
        });
        this.route("suppliers", {
          path: "/suppliers"
        });
        this.route("notices", {
          path: "/notices"
        });
        this.route("noAccess", {
          path: "/no-access"
        });
      });
    }
  };
});
define("discourse/plugins/discourse-subscription-client/discourse/routes/admin-plugins-subscription-client-notices", ["exports", "discourse/plugins/discourse-subscription-client/discourse/models/subscription-client-notice", "discourse/routes/discourse", "@ember/array"], function (_exports, _subscriptionClientNotice, _discourse, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    queryParams: {
      all: {
        refreshModel: true
      },
      hidden: {
        refreshModel: true
      }
    },
    model(params) {
      return _subscriptionClientNotice.default.list({
        include_all: params.all,
        visible: !params.hidden
      });
    },
    setupController(controller, model) {
      controller.setProperties({
        hiddenNoticeCount: model.hidden_notice_count,
        notices: (0, _array.A)(model.notices.map(notice => _subscriptionClientNotice.default.create(notice)))
      });
    }
  });
});
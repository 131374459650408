define("discourse/plugins/discourse-subscription-client/discourse/routes/admin-plugins-subscription-client-suppliers", ["exports", "discourse/plugins/discourse-subscription-client/discourse/models/subscription-client-supplier", "discourse/routes/discourse", "@ember/array", "discourse/models/user"], function (_exports, _subscriptionClientSupplier, _discourse, _array, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _subscriptionClientSupplier.default.list();
    },
    setupController(controller, model) {
      const suppliers = model.suppliers.map(supplier => {
        if (supplier.user) {
          supplier.user = _user.default.create(supplier.user);
        }
        return supplier;
      });
      controller.setProperties({
        suppliers: (0, _array.A)(suppliers)
      });
    }
  });
});
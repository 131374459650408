define("discourse/plugins/discourse-subscription-client/discourse/models/subscription-client-supplier", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object"], function (_exports, _ajax, _ajaxError, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SubscriptionClientSupplier = _object.default.extend();
  const basePath = "/admin/plugins/subscription-client/suppliers";
  SubscriptionClientSupplier.reopenClass({
    list() {
      return (0, _ajax.ajax)(basePath).catch(_ajaxError.popupAjaxError);
    },
    authorize(supplierId) {
      window.location.href = `${basePath}/authorize?supplier_id=${supplierId}`;
    },
    deauthorize(supplierId) {
      return (0, _ajax.ajax)(`${basePath}/authorize`, {
        type: "DELETE",
        data: {
          supplier_id: supplierId
        }
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = SubscriptionClientSupplier;
});
define("discourse/plugins/discourse-subscription-client/discourse/components/subscription-client-notice", ["exports", "@ember/component", "discourse/plugins/discourse-subscription-client/discourse/mixins/notice-message"], function (_exports, _component, _noticeMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_noticeMessage.default, {
    attributeBindings: ["notice.id:data-notice-id"],
    classNameBindings: [":subscription-client-notice", "notice.typeClass", "notice.dismissed:dismissed", "notice.expired:expired", "notice.hidden:hidden"],
    actions: {
      dismiss() {
        this.set("dismissing", true);
        this.notice.dismiss().then(() => {
          this.set("dismissing", false);
        });
      },
      hide() {
        this.set("hiding", true);
        this.notice.hide().then(() => {
          this.set("hiding", false);
        });
      }
    }
  });
});
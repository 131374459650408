define("discourse/plugins/discourse-subscription-client/discourse/models/subscription-client-notice", ["exports", "@ember/object", "discourse-common/utils/decorators", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object/computed", "@ember/string", "I18n"], function (_exports, _object, _decorators, _ajax, _ajaxError, _computed, _string, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const basePath = "/admin/plugins/subscription-client/notices";
  const SubscriptionClientNotice = _object.default.extend((_dec = (0, _decorators.default)("notice_type"), _dec2 = (0, _decorators.default)("notice_type"), (_obj = {
    expired: (0, _computed.notEmpty)("expired_at"),
    dismissed: (0, _computed.notEmpty)("dismissed_at"),
    hidden: (0, _computed.notEmpty)("hidden_at"),
    notHidden: (0, _computed.not)("hidden"),
    notDismissed: (0, _computed.not)("dismissed"),
    canDismiss: (0, _computed.and)("dismissable", "notDismissed"),
    canHide: (0, _computed.and)("can_hide", "notHidden"),
    typeClass(noticeType) {
      return (0, _string.dasherize)(noticeType);
    },
    typeLabel(noticeType) {
      return _I18n.default.t(`admin.subscription_client.notice.type.${noticeType}`);
    },
    dismiss() {
      if (!this.get("canDismiss")) {
        return;
      }
      return (0, _ajax.ajax)(`${basePath}/${this.get("id")}/dismiss`, {
        type: "PUT"
      }).then(result => {
        if (result.success) {
          this.set("dismissed_at", result.dismissed_at);
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    hide() {
      if (!this.get("canHide")) {
        return;
      }
      return (0, _ajax.ajax)(`${basePath}/${this.get("id")}/hide`, {
        type: "PUT"
      }).then(result => {
        if (result.success) {
          this.set("hidden_at", result.hidden_at);
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    show() {
      if (!this.get("hidden")) {
        return;
      }
      return (0, _ajax.ajax)(`${basePath}/${this.get("id")}/show`, {
        type: "PUT"
      }).then(result => {
        if (result.success) {
          this.set("hidden_at", null);
        }
      }).catch(_ajaxError.popupAjaxError);
    }
  }, (_applyDecoratedDescriptor(_obj, "typeClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "typeClass"), _obj), _applyDecoratedDescriptor(_obj, "typeLabel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "typeLabel"), _obj)), _obj)));
  SubscriptionClientNotice.reopenClass({
    list() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return (0, _ajax.ajax)(`${basePath}`, {
        type: "GET",
        data
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = SubscriptionClientNotice;
});
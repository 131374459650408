define("discourse/plugins/discourse-subscription-client/discourse/controllers/admin-plugins-subscription-client-subscriptions", ["exports", "@ember/controller", "discourse/plugins/discourse-subscription-client/discourse/models/subscription-client-subscription", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _controller, _subscriptionClientSubscription, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.observes)("authorizedSupplierCount", "currentUser.can_manage_suppliers"), (_obj = {
    adminPluginsSubscriptionClient: (0, _controller.inject)(),
    authorizedSupplierCount: (0, _computed.alias)("adminPluginsSubscriptionClient.authorizedSupplierCount"),
    resourceCount: (0, _computed.alias)("adminPluginsSubscriptionClient.resourceCount"),
    classNameBindings: [":subscription", "subscription.active:active:inactive"],
    hasSubscriptions: (0, _computed.notEmpty)("subscriptions"),
    messageKey: "info",
    messageClass: "info",
    changeMessageKey() {
      if (this.resourceCount === 0) {
        this.set("messageKey", "no_resources");
      } else if (this.authorizedSupplierCount === 0) {
        let key = this.currentUser.can_manage_suppliers ? "no_authorized_suppliers" : "no_authorized_suppliers_no_access";
        this.set("messageKey", key);
      } else {
        this.set("messageKey", "info");
      }
    },
    actions: {
      update() {
        this.set("updating", true);
        _subscriptionClientSubscription.default.update().then(result => {
          if (result.subscriptions && result.subscriptions.length > 0) {
            this.setProperties({
              updateIcon: "check",
              subscriptions: result.subscriptions
            });
          } else {
            this.set("updateIcon", "times");
          }
        }).finally(() => {
          this.set("updating", false);
          setTimeout(() => {
            this.set("updateIcon", null);
          }, 7000);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "changeMessageKey", [_dec, _dec2], Object.getOwnPropertyDescriptor(_obj, "changeMessageKey"), _obj)), _obj)));
});
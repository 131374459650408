define("discourse/plugins/discourse-subscription-client/discourse/controllers/admin-plugins-subscription-client-suppliers", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    classNameBindings: [":suppliers"],
    hasSuppliers: (0, _computed.notEmpty)("suppliers"),
    messageKey: "info",
    messageClass: "info"
  });
});
define("discourse/plugins/discourse-subscription-client/discourse/helpers/notice-badge", ["discourse/lib/formatter", "discourse-common/lib/icon-library", "I18n", "discourse-common/lib/helpers", "@ember/template"], function (_formatter, _iconLibrary, _I18n, _helpers, _template) {
  "use strict";

  (0, _helpers.registerUnbound)("notice-badge", function (attrs) {
    let tag = attrs.url ? "a" : "div";
    let attrStr = "";
    if (attrs.title) {
      attrStr += `title='${_I18n.default.t(attrs.title)}'`;
    }
    if (attrs.url) {
      attrStr += `href='${attrs.url}'`;
    }
    let html = `<${tag} class="${attrs.class ? `${attrs.class} ` : ""}notice-badge" ${attrStr}>`;
    if (attrs.icon) {
      html += (0, _iconLibrary.iconHTML)(attrs.icon);
    }
    if (attrs.label) {
      if (attrs.icon) {
        html += "&nbsp;";
      }
      html += `<span>${_I18n.default.t(attrs.label)}</span>`;
    }
    if (attrs.date) {
      if (attrs.icon || attrs.label) {
        html += "&nbsp;";
      }
      let dateAttrs = {};
      if (attrs.leaveAgo) {
        dateAttrs = {
          format: "medium",
          leaveAgo: true
        };
      }
      html += (0, _formatter.autoUpdatingRelativeAge)(new Date(attrs.date), dateAttrs);
    }
    html += `</${tag}>`;
    return (0, _template.htmlSafe)(html);
  });
});